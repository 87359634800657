<template>
  <div class="blog-wrapper p-1">
    <MenuComponent />
    <br/>
    <b-row>
    <b-col sm="1"></b-col>
    <b-col sm="10">
    <content-with-sidebar class="blog-wrapper">
      <!-- blogs -->
      <b-row class="blog-list-wrapper">
        <b-col md="6" v-for="blog in dataBlog" v-bind:key="blog.id">
          <b-card tag="article" no-body>
            <b-link :href="'/blog/detail/' + blog.id">
              <b-img
                class="card-img-top"
                :src="getUrl + 'penyimpanan/blog/' + blog.photo"
              />
            </b-link>
            <b-card-body>
              <b-card-title>
                <b-link class="blog-title-truncate text-body-heading">
                  {{ blog.title }}
                </b-link>
              </b-card-title>
              <b-media no-body>
                <b-link class="brand-logo" href="/">
                  <b-img
                    :src="require('@/assets/images/avatars/13-small.png')"
                    style="width: 20px; height: 20px"
                    alt="logo"
                    class=""
                  ></b-img>
                </b-link>
                <b-media-body>
                  <small class="text mr-50"> by</small>
                  <small>
                    <b-link class="text-body">Optimal</b-link>
                  </small>
                  <span class="text-muted ml-75 mr-50">|</span>
                  <small class="text">Informasi</small>
                </b-media-body>
              </b-media>
              <div class="my-1 py-25">
                <b-link>
                  <b-badge pill class="mr-75" variant="info"> Umum </b-badge>
                </b-link>
                <b-link>
                  <b-badge pill class="mr-75" variant="success">
                    Kesehatan
                  </b-badge>
                </b-link>
                <b-link>
                  <b-badge pill class="mr-75" variant="warning">
                    Layanan
                  </b-badge>
                </b-link>
                <b-link>
                  <b-badge pill class="mr-75" variant="primary"> Tips </b-badge>
                </b-link>
                <b-link>
                  <b-badge pill class="mr-75" variant="danger"> Medis </b-badge>
                </b-link>
              </div>
              <b-card-text class="blog-content-truncate">
                {{ blog.description }}
              </b-card-text>
              <hr />
              <div class="d-flex justify-content-between align-items-center">
                <b-link>
                  <div class="d-flex align-items-center text-body">
                    <feather-icon icon="MessageSquareIcon" class="mr-50" />
                    <span class="font-weight-bold"> Terbaru</span>
                  </div>
                </b-link>
                <b-link class="font-weight-bold"> Baca Selengkapnya </b-link>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col cols="12">
          <!-- pagination -->
          <div class="my-2">
            <b-pagination
              v-model="currentPage"
              align="center"
              :total-rows="rows"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-col>
      </b-row>
      <!--/ blogs -->

      <!-- sidebar -->
      <div slot="sidebar" class="blog-sidebar py-2 py-lg-0">
        <!-- input search -->
        <b-form-group class="blog-search">
          <b-input-group class="input-group-merge">
            <b-form-input id="search-input" placeholder="Cari Disini" />
            <b-input-group-append class="cursor-pointer" is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <!--/ input search -->

        <!-- recent posts -->
        <div class="blog-recent-posts mt-3">
          <h6 class="mb-75">Lihat Paket Juga</h6>
          <b-card style="background-color: #e6cce6" v-for="paket in datapaket" v-bind:key="paket.id" class="mb-1" no-body>
                      
          <b-media no-body class="p-1">
            <b-link :href="paket.link">
              <b-media-aside class="mr-2 mb-75">
                <b-img
                  :src="getUrl + 'penyimpanan/paket/' + paket.photo"
                  width="50px"
                  rounded
                />
              </b-media-aside>
            </b-link>
            <b-link :href="paket.link">
              <b-media-body>
                <h6 class="blog-recent-post-title">
                  <b-link class="text-body-heading"> {{ paket.title }} </b-link>
                </h6>
                <!-- <p></p> -->
                <span class="text-body mb-0">
                  <b> Rp.{{ formatPrice(paket.price) }} </b>
                </span>
                <p></p>
                <!-- <span class="text-body mb-0">
              <a style="color:#7a2a85"> Team Optimal </a>
            </span> -->
              </b-media-body>
            </b-link>
          </b-media>
        </b-card>
        </div>
        <!--/ recent posts -->

        <!-- categories -->
        <div class="blog-categories mt-3">
          <h6 class="mb-1">Kategori</h6>

          <div class="d-flex justify-content-start align-items-center mb-75">
            <b-link>
              <b-avatar rounded size="32" variant="info" class="mr-75">
                <feather-icon icon="AlertCircleIcon" size="16" />
              </b-avatar>
            </b-link>
            <b-link>
              <div class="blog-category-title text-body">Umum</div>
            </b-link>
          </div>
          <div class="d-flex justify-content-start align-items-center mb-75">
            <b-link>
              <b-avatar rounded size="32" variant="success" class="mr-75">
                <feather-icon icon="AlertCircleIcon" size="16" />
              </b-avatar>
            </b-link>
            <b-link>
              <div class="blog-category-title text-body">Kesehatan</div>
            </b-link>
          </div>
          <div class="d-flex justify-content-start align-items-center mb-75">
            <b-link>
              <b-avatar rounded size="32" variant="warning" class="mr-75">
                <feather-icon icon="AlertCircleIcon" size="16" />
              </b-avatar>
            </b-link>
            <b-link>
              <div class="blog-category-title text-body">Layanan</div>
            </b-link>
          </div>
          <div class="d-flex justify-content-start align-items-center mb-75">
            <b-link>
              <b-avatar rounded size="32" variant="primary" class="mr-75">
                <feather-icon icon="AlertCircleIcon" size="16" />
              </b-avatar>
            </b-link>
            <b-link>
              <div class="blog-category-title text-body">Tips</div>
            </b-link>
          </div>
          <div class="d-flex justify-content-start align-items-center mb-75">
            <b-link>
              <b-avatar rounded size="32" variant="danger" class="mr-75">
                <feather-icon icon="AlertCircleIcon" size="16" />
              </b-avatar>
            </b-link>
            <b-link>
              <div class="blog-category-title text-body">Medis</div>
            </b-link>
          </div>
        </div>
        <!--/ categories -->
      </div>
      <!--/ sidebar -->
    </content-with-sidebar>
  </b-col>
  </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BCardText,
  BCardTitle,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BImg,
  BCardBody,
  BLink,
  BBadge,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
} from "bootstrap-vue";
import ContentWithSidebar from "@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue";
import MenuComponent from "@/views/Menu.vue";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    MenuComponent,
    BFormInput,
    BCardText,
    BCardBody,
    BCardTitle,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BBadge,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BPagination,
    ContentWithSidebar,
  },
  data() {
    return {
      search_query: "",
      currentPage: 1,
      perPage: 1,
      rows: 50,
      dataBlog: [],
      datapaket: [],
      selected: "left",
      selected2: "right",
      level: localStorage.getItem("Ulevel"),
      getUrl: "",
    };
  },
  async mounted() {
    await this.getData();
    this.getDatapaket();
    this.getUrl = process.env.VUE_APP_URL;
  },
  methods: {
    async getData() {
      await this.$http
        .get(process.env.VUE_APP_BACKEND_URL + "blogs/view")
        .then((res) => {
          this.dataBlog = res.data.data;
        });
    },
    async getDatapaket() {
      await this.$http
        .get(process.env.VUE_APP_BACKEND_URL + "pakets/view_limit")
        .then((res) => {
          this.datapaket = res.data.data;
        });
    },
    formatPrice(value) {
      if (value != null) {
        const val = Number(value).toFixed(2).replace(/\.00$/, "");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-blog.scss";
</style>
